<template>
	<div class="myMain margin_auto flex">
		<!-- 移动端用户中心 -->
		<template v-if="isMobileDevice">
			<IndexMobile></IndexMobile>
		</template>
		<!-- pc端用户中心 -->
		<template v-else>
			<LeftNav :currentPath="'my'"></LeftNav>
			<div class="myMain_r mine_content_right">
				<Individual></Individual>
			</div>
		</template>
	</div>
</template>

<script>
import IndexMobile from "./index_mobile.vue";
import Individual from "./Individual/index";
import Mywz from "./mywz/index";
import { getToken } from "@/utils/auth";
import Publish from "./mywz/publish";
import LeftNav from "./leftNav";
export default {
	name: "",
	components: {
		Individual,
		Mywz,
		Publish,
		LeftNav,
		IndexMobile,
	},
	data() {
		return {
			wzid: "",
		};
	},
	created() {
		const hasToken = getToken();
		console.log(hasToken);
		if (hasToken) {
		} else {
			this.$router.push({ path: "/" });
			// location.reload();
		}
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
	methods: {},
};
</script>

<style scoped>
.myMain {
	width: 100%;
}
.myMain_r {
	width: 100%;
	opacity: 1;
	padding-top: 20px;
}
.active span {
	color: #0056ff;
}
</style>
