<template>
	<div class="my_wraper">
		<div class="user_info_part">
			<div class="main_info flex">
				<div class="avatar_img"><img :src="userInfo.avatar_img" /></div>
				<div>
					<p class="nickname">{{ userInfo.nickname }}</p>
					<p class="uid">ID:{{ userInfo.id }}</p>
				</div>
			</div>
			<!-- 点击这里填写简介 -->
			<div class="sub_info">{{ userInfo.desc }}</div>
		</div>
		<div class="action-box flex">
			<div class="type_num">
				<p class="info_type">已关注</p>
				<p class="info_num">{{ userInfo.follow_num }}</p>
			</div>
			<div class="type_num">
				<p class="info_type">被关注</p>
				<p class="info_num">{{ userInfo.fans_num }}</p>
			</div>
			<!-- <div class="">编辑资料</div>
			<div class="">f发布文章</div> -->
		</div>
		<div class="nav_box flex">
			<div
				class="per_nav"
				v-for="(nav, index) in myNavList"
				@click="toPath(nav)"
				:key="index"
			>
				<img class="nav_img" :src="nav.img" />
				<div class="nav_tag">{{ nav.tag }}</div>
			</div>
		</div>
	</div>
</template>
<script>
import { getInfo } from "@/api/user";
import { toUserInfo } from "@/api/home";
export default {
	data() {
		return {
			userInfo: {},
			myNavList: [
				{
					img: "https://res.metaera.hk/resources/assets/images/my/collect.svg",
					link: "collect",
					tag: "我的收藏",
				},
				{
					img: "https://res.metaera.hk/resources/assets/images/my/myactive.svg",
					link: "ac_mine",
					tag: "我参与的活动",
				},
				{
					img: "https://res.metaera.hk/resources/assets/images/my/myactive.svg",
					link: "ac_organ",
					tag: "我创建的活动",
				},
				{
					link: "contactus",
					img: "https://res.metaera.hk/resources/assets/images/my/商务合作@3x.png",
					tag: "商务合作",
				},
				{
					link: "contactus",
					img: "https://res.metaera.hk/resources/assets/images/my/内容合作@3x.png",
					tag: "内容合作",
				},
				{
					link: "contactus",
					img: "https://res.metaera.hk/resources/assets/images/my/社交媒体@3x.png",
					tag: "社交媒体",
				},
			],
		};
	},
	created() {
		this.getInfo();
	},
	methods: {
		getInfo() {
			getInfo({})
				.then((res) => {
					if (res.code == 200) {
						// this.userInfo = res.data;
						// this.userInfo.sex = this.userInfo.sex.toString();
						console.log("用户信息", this.userInfo);
						this.gettoUserInfo(res.data.id);
					}
				})
				.catch(() => {});
		},
		gettoUserInfo(id) {
			toUserInfo({ id: id })
				.then((res) => {
					if (res.code == 200) {
						this.userInfo = res.data;
						console.log("this.userInfo = res.data;", res.data);
						// this.getUserInformation(res.data.id);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},

		toPath(item) {
			this.$router.push({ path: item.link });
		},
	},
};
</script>
<style scoped>
.my_wraper {
	padding: 22px 15px;
	box-sizing: border-box;
	width: 100%;
}
.main_info {
	align-items: center;
}
.avatar_img {
	margin-right: 10px;
}
.avatar_img img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}
.nickname {
	font-size: 16px;
	line-height: 1;
	font-weight: 600;
	color: #fff;
}
.uid {
	font-size: 14px;
	line-height: 1;
	color: #aaa;
	margin-top: 10px;
}
.sub_info {
	font-size: 14px;
	line-height: 1;
	color: #fff;
	margin-top: 20px;
}
.type_num {
	margin-right: 15px;
	margin-left: 5px;
}
.action-box {
	margin-top: 28px;
	color: #fff;
}
.info_type {
	font-size: 12px;
	line-height: 1;
}
.info_num {
	font-size: 14px;
	line-height: 1;
	font-weight: 600;
	margin-top: 10px;
	text-align: center;
}
.nav_box {
	flex-wrap: wrap;
	row-gap: 28px;
	padding-top: 25px;
	margin-top: 25px;
	border-top: 1px solid #fff;
}
.per_nav {
	width: 33%;
	text-align: center;
}
.nav_img {
	width: 20px;
	height: 20px;
}
.nav_tag {
	margin-top: 15px;
	color: #fff;
	font-size: 10px;
	line-height: 1;
}
</style>
